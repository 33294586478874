import {
    Box, AspectRatio, Text, Heading, VStack, Button, Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    chakra,
    useDisclosure,
} from "@chakra-ui/react";

export const GeneralTerms = (props) => {
    const {isOpen, onOpen, onClose} = useDisclosure()

    return (
        <>
            <VStack {...props}>
                <Text fontFamily='acuraBespoke' color='white'
                      fontWeight={{base: 'bold', md: 'normal'}}
                      fontSize={{base: '16px', md: '18px'}}
                      cursor='pointer' textDecor='underline' onClick={onOpen}
                >
                    Click here for Acura Energy Key Promotional Terms
                </Text>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose} size='xl' scrollBehavior='inside'>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader bg='black' textColor='white' fontFamily='acuraBespoke' fontWeight='bold'>
                        ACURA ENERGY KEY PROMOTIONAL TERMS
                    </ModalHeader>
                    {/*<ModalCloseButton color='white'/>*/}
                    <ModalBody bg='black'>
                        <VStack>
                            {/*<Heading as='h1' fontFamily='acuraBespoke' color='white' fontSize='22px' fontWeight='bold'>*/}
                            {/*    */}
                            {/*</Heading>*/}

                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                <chakra.span textDecor='underline'>Eligibility:</chakra.span>
                                This promotional program (“Program”) is open only to legal residents of the fifty (50)
                                United States and the District of Columbia, who are eighteen (18) years of age or older
                                at the time of participation. Void where prohibited. Employees of American Honda Motor
                                Co., Inc. (“Acura”) and its parents, dealers, affiliates, subsidiaries, vendors, and
                                advertising and promotion agencies, as well as the immediate family (spouse, parents,
                                siblings and children) and household members of each such employee are not eligible to
                                participate in the Program. No groups, clubs, corporations, companies, partnerships, or
                                organizations may participate in this Program. Participation in the Program constitutes
                                each participant's full and unconditional agreement to these terms, and you represent
                                that you meet the eligibility requirements and all other requirements set out in these
                                terms.
                            </Text>

                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                <chakra.span textDecor='underline'>How to Receive An Energy Key:</chakra.span>
                                When reservations open for the 2024 Acura ZDX (“Vehicle”), eligible participating
                                individuals who complete a reservation for the Vehicle will be eligible to receive an
                                Energy Key (“Key”) that provides participants with certain promotions and benefits from
                                Acura’s third-party promotional providers, each while supplies last (“Perks”). Acura
                                will send qualifying reservation holders a reservation confirmation email that also
                                contains their unique Energy Key ID. Individuals seeking to claim their Key and Perks
                                must enter their digital Energy Key ID where prompted at www.UnlockAcuraZDX.com.
                                Physical Keys will also be delivered by mail within one (1) – three (3) weeks after
                                confirmation of your eligibility in the Program.
                            </Text>

                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                Perks may be added or removed throughout the Program, and if limited, are offered on a
                                first-come, first-served basis. Perks may be subject to additional terms and conditions
                                set by third-party promotional providers. Completion of a reservation will require a
                                refundable reservation fee and is subject to the Acura Vehicle Reserve Terms and
                                Conditions and any other additional terms and conditions set by the participating
                                dealer. Limit: one (1) Energy Key per qualifying Vehicle reservation. If a participating
                                individual’s reservation is cancelled or refunded for any reason, the Energy Key will
                                not be delivered. Energy Keys will be delivered to the email used to place the Vehicle
                                reservation.
                            </Text>

                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                <chakra.span textDecor='underline'>Other Terms and Conditions:</chakra.span>
                                The information provided in connection with this Program is subject to Acura’s Privacy
                                Notice available at: https://www.honda.com/privacy/privacy-notice. Acura expressly
                                reserves the right to amend, suspend or terminate the Program at any time.
                                Administration of the Program is at the sole discretion of Acura. Any questions relating
                                to eligibility, these terms and conditions or any other questions concerning the Program
                                will be resolved at the sole discretion of Acura and its decisions will be final and
                                binding with respect thereto. No requests for exchanges, or cash equivalent for the Keys
                                or Perks will be considered and no substitutions will be made. Acura is not responsible
                                for any inability to claim a Key, or for incorrect or inaccurate redemption or Program
                                information, whether caused by any third-party individuals, or by any of the equipment,
                                software, application, or programming associated with or utilized in the Program or by
                                any technical or human error which may occur in the processing of the Keys. Acura
                                reserves the right in its sole discretion to cancel or suspend this Program, including
                                if fraud, virus, bugs or other causes beyond Acura’s control corrupt the administration,
                                security, or proper conduct of the Program. Acura is not responsible for computer
                                system, hardware, software or program malfunctions, or other errors, failures, or delays
                                in computer transmissions or network connections. Automated redemption requests are
                                prohibited, and any use of automated devices in connection with the Program will cause
                                disqualification. Participants may not participate with multiple email addresses or
                                identities nor may participants use any other device or artifice to attempt to obtain
                                multiple Keys. Acura, in its sole discretion, may reject any redemption request from any
                                participant whom Acura believes is attempting to participate with multiple email
                                addresses, under multiple identities, or uses any device or artifice to participate
                                multiple times, and such participant will forfeit any Key or Perks, in Acura's
                                discretion. Acura reserves the right to suspend or discontinue the eligibility of any
                                person who uses or is suspected of violating these terms and conditions or any federal
                                or state laws, regulations, or ordinances, without prejudice to any other legal remedies
                                Acura may have.
                            </Text>

                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                By participating in this Program, you agree to release, defend, indemnify and hold
                                harmless Acura, and its parent corporations, subsidiaries, affiliates, advertising and
                                promotion agencies, third-party Perk providers, and each of their directors, officers,
                                employees, representatives and agents (collectively “Released Parties”) from and against
                                any and all liability claims or actions of any kind whatsoever (however named or
                                described) arising out of the Program. Acura expressly disclaims any responsibility or
                                liability for injury or loss to any person or property relating to the Key. IN NO EVENT
                                WILL ACURA OR ANY OF THE RELEASED PARTIES BE LIABLE, WHETHER UNDER ANY THEORY OF
                                LIABILITY, FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, LOSS OF
                                REVENUE OR PROFITS, LOSS OF DATA, OR OTHER LOSS OF BUSINESS, EVEN IF SUCH PARTY IS
                                NOTIFIED IN ADVANCE OF SUCH POSSIBILITY, ARISING OUT OF THESE TERMS.
                            </Text>

                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                <chakra.span textDecor='underline'>Binding Arbitration:</chakra.span>
                                Any controversy, claim or dispute arising out of or related to these terms or the Key,
                                including, but not limited to, alleged violations of state or federal statutory or
                                common law rights or duties (a “Dispute”) shall be solely and exclusively resolved
                                according to the procedures set forth in this paragraph. If the parties are unable to
                                resolve any Dispute through informal means, either party may initiate binding
                                arbitration of such Dispute by sending notice demanding arbitration to the other party.
                                The demand for arbitration shall be made within a reasonable time after the Dispute has
                                arisen, but in no event shall it be made more than one year from when the aggrieved
                                party knew or should have known of the controversy, claim or facts forming the basis of
                                the Dispute. The arbitration shall be initiated and conducted according to American
                                Arbitration Association rules and procedures for consumer arbitration (the “Arbitration
                                Rules”), including its provisions permitting Disputes falling within the jurisdiction of
                                small claims court to be filed in small claims court. The arbitration shall be conducted
                                in Los Angeles County, California before a single neutral arbitrator appointed in
                                accordance with the Arbitration Rules with the option to appeal the arbitrator’s
                                decision to Optional Appellate Arbitration in accordance with the Arbitration Rules.
                                Disputes with an amount in controversy under $10,000 will be decided by the arbitrator
                                solely upon written submissions without a hearing. To the fullest extent permitted by
                                law, the arbitrator shall not have the power to award punitive, special, consequential
                                or indirect damages against any party. Arbitration costs and fees shall be determined in
                                accordance with the Arbitration Rules. Attorneys’ fees shall be borne by each party
                                independently and no party shall be liable for the attorneys’ fees of the other party.
                                No disputes may be arbitrated on a class or representative basis and the arbitrator may
                                not consolidate or join the claims of other persons or parties who may be similarly
                                situated. BY AGREEING TO THESE TERMS, EACH PARTY IRREVOCABLY WAIVES ANY RIGHT IT MAY
                                HAVE TO JOIN CLAIMS OR DISPUTES WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION,
                                CLASS ARBITRATION OR SIMILAR PROCEDURAL DEVICE; AND WAIVES ANY RIGHT IT MAY HAVE TO
                                PRESENT ITS CLAIM OR DISPUTE IN A COURT OF LAW. Judgment on the award rendered by the
                                arbitrator(s), if any, may be entered for enforcement purposes in any court having
                                jurisdiction thereof. Subject to the dispute resolution process described above, all
                                claims, disputes, and suits (including Disputes filed in small claims court pursuant to
                                the Arbitration Rules) must be brought solely in the state or federal courts located in
                                Los Angeles County, California, and you agree to the jurisdiction thereof. These terms
                                shall be governed by the laws of the State of California without regard to conflict of
                                law principles.
                            </Text>
                        </VStack>
                    </ModalBody>

                    <ModalFooter bg='black'>
                        <Button colorScheme='white' mr={3} onClick={onClose} fontFamily='acuraBespoke'
                                fontWeight='bold'>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}