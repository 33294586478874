import {
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Image, Text, VStack, Spacer,
} from '@chakra-ui/react'

export const KeyIdOverlayDesktop = ({isOpen, onClose, display}) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
            <ModalOverlay display={display}/>
            <ModalContent bg='black' minW='900px' minH='500px' mt='185px' py='15spx' display={display}>
                <ModalHeader>
                    <HStack spacing={4} mt='15px' ml='10px'>
                        <Image src='images/acura_badge.png' w='68px'/>
                        <Text
                            w='245px'
                            color='white'
                            fontSize='20px'
                            fontWeight='950'
                            fontFamily='FuturaNowAcura'
                            fontStyle='italic'
                            textTransform='uppercase'
                            lineHeight={1}
                            letterSpacing={1.4}
                        >
                            Where can I find my energy key id?
                        </Text>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton mt='15px' mr='10px'>
                    <Image src='images/icon_close_x.png' w='18px'/>
                </ModalCloseButton>
                <ModalBody ml='95px'>
                    <HStack alignContent='start' justifyContent='center'>
                        <VStack align='left' w='30%' spacing={4}>
                            <Text color='white' fontSize='20px' fontFamily='FuturaNowAcura' fontWeight={950}
                                  fontStyle='italic'>
                                YOUR ENERGY KEY ID
                            </Text>
                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                Within 1-2 weeks following your reservation confirmation, you will receive an email from Acura with the subject line “Welcome to the House of Energy”, this is where your Energy Key ID will be located.
                            </Text>
                        </VStack>
                        <Spacer/>
                        <Image mr='100px' mb='60px' src='images/overlay_instructions.jpg' w='200px'/>
                    </HStack>
                    <Image position='absolute' top='333px' left='360px' src='images/overlay_long_arrow.png' w='200px'/>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}