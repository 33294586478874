import {Box, AspectRatio, Flex, Center, Text, Heading, VStack, chakra} from "@chakra-ui/react";

export const Section5 = () => {
    return (
        <AspectRatio ratio={{base: 0.268, md: 1.8}} id='section5'>
            <Flex flexDirection={{base: 'column-reverse', md: 'row'}}>
                <Box
                    w='100%'
                    h='100%'
                    bgImage='images/bg_section_5L_desktop.jpg'
                    backgroundSize='cover'
                    bgPos={{base: '50% 50%', md: '50% 50%'}}
                />

                <Box
                    w='100%'
                    h='100%'
                    bgImage='images/bg_section_5R_desktop.jpg'
                    backgroundSize='cover'
                    bgPos={{base: '90% 50%', md: '50% 50%'}}
                >
                    <Center h='100%' p={{base: '8%', md: '25%'}} mt={{base: '-30px', md: '0'}}>
                        <VStack align='left' spacing={8}>
                            <Heading as='h1' fontSize='32px' color='white' fontFamily='FuturaNowAcura' fontWeight={950}
                                     fontStyle='italic'>
                                {/*EVENT PERKS*/}
                            </Heading>
                            <Text fontFamily='acuraBespoke' color='white' fontSize='18px' fontWeight='normal'
                                  lineHeight={{base: '6', md: '2vw'}}>
                                {/*Upon reservation of your all-electric ZDX, you’ll receive exclusive perks for*/}
                                {/*Acura-sponsored events.*/}
                                <br/><br/>
                                {/*A limited quantity of tickets to virtually screen the winner of the*/}
                                {/*<chakra.span textDecor='underline' cursor='pointer' onClick={() => window.open("https://d2wsrejhnxatgp.cloudfront.net/assest/live/SFF24_Program_Guide.pdf", "_blank")}>*/}
                                {/*    &nbsp;Acura-Sponsored Sundance Film Festival U.S. Dramatic Competition Audience Award*/}
                                {/*</chakra.span> will be available*/}
                                {/*(tickets are limited and will be distributed on a first-come, first-serve basis, limited*/}
                                {/*to 1x ticket per reservation holder). For further details and instructions on how to*/}
                                {/*claim a ticket, enter your Energy Key ID above to gain access to the House of Energy.*/}
                                {/*<br/><br/>*/}
                                {/*Each ZDX Energy Key holder attending the Sundance Film Festival will have access to the*/}
                                {/*VIP lounge at the Acura House of Energy.*/}
                                {/*<br/><br/>*/}
                                {/*Stay tuned for details surrounding the Acura Grand Prix of Long Beach.*/}
                            </Text>
                        </VStack>
                    </Center>
                </Box>
            </Flex>
        </AspectRatio>
    )
}

/*
Upon reservation of your all-electric ZDX, you’ll receive exclusive perks for
                                Acura-sponsored events, including the Sundance Film Festival, and the Acura Grand Prix
                                of Long Beach. Stay tuned for more details on event perks to come.
 */