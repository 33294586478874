import {
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Center,
    ModalBody,
    ModalCloseButton,
    Image, Text, VStack,
} from '@chakra-ui/react'

export const KeyIdOverlayMobile = ({isOpen, onClose, display}) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='full' scrollBehavior="inside">
            <ModalOverlay display={display}/>
            <ModalContent bg='black' py='5px' display={display}>
                <ModalHeader>
                    <HStack spacing={4} mt='5px' ml='5px'>
                        <Image src='images/acura_badge.png' w='48px'/>
                        <Text
                            w='245px'
                            color='white'
                            fontSize='18px'
                            fontWeight='950'
                            fontFamily='FuturaNowAcura'
                            fontStyle='italic'
                            textTransform='uppercase'
                            lineHeight={1}
                            letterSpacing={1.4}
                        >
                            Where can I find my energy key id?
                        </Text>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton mt='30px' mr='5px'>
                    <Image src='images/icon_close_x.png' w='18px'/>
                </ModalCloseButton>
                <ModalBody>
                    <Center>
                        <VStack alignContent='start' justifyContent='center' mt='10px'>
                            <VStack spacing={2} align='left'>
                                <Image src='images/overlay_instructions.jpg' w='245px'/>
                            </VStack>

                            <Image src='images/overlay_long_arrow_up.png' h='30px'/>

                            <VStack align='left' w='73%' spacing={4} >
                                <Text color='white' fontSize='20px' fontFamily='FuturaNowAcura' fontWeight={950}
                                      fontStyle='italic'>
                                    YOUR ENERGY KEY ID
                                </Text>
                                <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                    Within 1-2 weeks following your reservation confirmation, you will receive an email
                                    from Acura with the subject line “Welcome to the House of Energy”, this is where
                                    your Energy Key ID will be located.
                                </Text>
                            </VStack>

                        </VStack>
                    </Center>
                    {/*<Image position='absolute' top='405px' left='49%' src='images/overlay_long_arrow_up.png' h='195px'*/}
                    {/*       w='8px'/>*/}
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}