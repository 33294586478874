import {
    Routes,
    Route,
    useLocation,
    Navigate,
} from "react-router-dom";
import {Home} from "./components/Home";
import {Perks} from "./components/Perks";
import {Tracker} from "./components/Tracker";

function App() {
    const location = useLocation();

    document.body.style.padding = "0";
    document.body.style.margin = "0";

    const Redirect = () => {
        window.location.replace('https://acura-energy-reading-master.monogrid.io/en/');
    }

    return (
        <>
            <Routes location={location}>
                <Route
                    path="/"
                    element={<Home/>}
                />
                <Route
                    path="/perks"
                    element={<Perks/>}
                />
                <Route
                    path="/tracker"
                    element={<Tracker/>}
                />
                <Route
                    path='/energy'
                    element={<Redirect/>}
                >

                </Route>
            </Routes>
        </>
    );
}

export default App;
