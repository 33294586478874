import {Box, AspectRatio, Center, Image, Text, Heading, VStack, Button, Input} from "@chakra-ui/react";

export const Section9 = () => {
    return (
        <AspectRatio ratio={{base: 0.5, md: 1.8}} id='section9'>
            <Box
                bgImage={{base: 'images/bg_section_9_mobile.jpg', md: 'images/bg_section_9_desktop.jpg'}}
                backgroundSize='cover'
                bgPos={{base: '15% 50%', md: '50% 50%'}}
            />
        </AspectRatio>
    )
}