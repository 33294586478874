import {ChakraProvider, extendTheme} from '@chakra-ui/react'

const theme = extendTheme({
    styles: {
        global: {
            html: {
                scrollBehavior: 'smooth',
            },
        },
    },
    // fonts: {
    //     body: `'Poppins', sans-serif`,
    //     arkit: `'ARKit', sans-serif`,
    // },
});


export const Providers = ({children}) => {
    return (
        <ChakraProvider theme={theme}>
            {children}
        </ChakraProvider>
    )
}