import { getStorage, ref, listAll, getMetadata } from "firebase/storage";
import {useEffect, useRef, useState} from "react";
import date from "date-and-time";
import { getDatabase, ref as dbRef, set } from "firebase/database";

export const Tracker = () => {

    const [codes, setCodes] = useState([])
    const codesRef = useRef()

    const storage = getStorage();
    // Create a reference under which you want to list
    const listRef = ref(storage, '/');

    useEffect(() => {

        let data = {}
        let dataArr = []

        const db = getDatabase()
        const saveCode = (zdxCode, dateData) => {
            const codesRef = dbRef(db, zdxCode)
            set(codesRef, {
                date: dateData
            });
        }

        // Find all the prefixes and items.
        listAll(listRef)
            .then((res) => {
                res.items.forEach((itemRef) => {
                    // All the items under listRef.
                    getMetadata(itemRef)
                        .then((metadata) => {

                            console.log(metadata.timeCreated)

                            const now = new Date(metadata.timeCreated);
                            const dateData = date.format(now, 'YYYY/MM/DD')

                            console.log(dateData)

                            data[metadata.name.split(".")[0]] = {
                                date: dateData
                            }

                            const zdxCode = metadata.name.split(".")[0]
                            dataArr.push(
                                {
                                    entry: {
                                        code: zdxCode,
                                        date: dateData
                                    }
                                }
                            )

                            console.log('saving:', zdxCode)
                            console.log('with date:', dateData)

                            saveCode(zdxCode, dateData)

                            // const db = getDatabase()
                            // const codesRef = ref(db, zdxCode)
                            // set(codesRef, {
                            //     date: dateData
                            // });
                        })
                        .catch((error) => {
                            console.log('error:', error)
                        });

                });
            }).catch((error) => {
            // Uh-oh, an error occurred!
        });

        // console.log(data)
        setCodes(dataArr)
        // codesRef.current = data
    }, []);

    useEffect(() => {
        if (codes.length > 0) {
            codes.map((entry) => {
                const zdxCode = entry.code
                const date = entry.date

                console.log('saving:', zdxCode)
                console.log('with date:', date)
            })
        }

    }, [codes]);

    return(
        <p>
            {/*{JSON.stringify(codes)}*/}
        </p>
    )
}