import {
    Box, AspectRatio, Center, Image, Text, Heading, VStack, Button, Input,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
import {GeneralTerms} from "../../ui/GeneralTerms/GeneralTerms";

export const Section8 = () => {
    return (
        <AspectRatio ratio={{base: 0.165, md: 0.785}} id='section8'>
            <Box
                bgImage='images/bg_section_8_desktop.jpg'
                backgroundSize='cover'
            >
                <VStack w='100%' spacing={{base: 12, md: 32}} align='left'>

                    <Heading as='h1' fontSize={{base: '32px', md: '48px'}} color='white' fontFamily='FuturaNowAcura'
                             fontWeight={950} fontStyle='italic' align={{base: 'left', md: 'center'}}
                             px={{base: '5%', md: '15%'}}>
                        FREQUENTLY ASKED QUESTIONS
                    </Heading>

                    <Accordion textColor='white' px={{base: '5%', md: '15%'}} allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic'>
                                        WHAT IS THE ENERGY KEY?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                It is the key to Unlock the Energy. It gives you access to exclusive perks,
                                including some before the vehicle goes on sale. Each reservation holder will
                                receive a physical, stainless steel Energy Key and a digital key that can be
                                added to their Apple or Google Wallet.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        How can I get a key?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                Following your reservation confirmation, you will receive an email with your unique
                                digital Energy Key ID, which you can use to access exclusive perks. Within the weeks
                                following your reservation, you will receive a premium stainless steel Energy Key at the
                                mailing address associated with your reservation.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        Does the key expire?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                No, the Energy Key itself never expires; however, there may be merch drops, perks, or
                                special events that are only available for a limited time or in limited quantities.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        Can I get the key without reserving a ZDX?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                No, the Energy Key is only available to those who reserve a ZDX.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        When can I expect my key?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                Within the weeks following your reservation, you will receive a premium stainless steel
                                Energy Key at the mailing address associated with your reservation.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        What is the difference between the digital Energy Key
                                        and the physical (stainless steel) Energy Key?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                Both keys grant you access to VIP experiences and perks at Acura-sponsored events.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        How do I receive my perks at an Acura-sponsored event if I haven’t received my
                                        Physical Energy Key or Energy Key ID yet?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                If you are a reservation holder that has not received their physical energy key or their
                                virtual energy key ID and you would like to go to an Acura-sponsored event, please show
                                your reservation confirmation email to receive your exclusive perks.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        How do I redeem my Bang & Olufsen discount code?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                Your Bang & Olufsen discount code can only be redeemed for select products only through
                                the microsite link located next to your discount code. After you’ve entered your Energy
                                Key code, you will receive a discount code and a link to the B&O microsite.
                                Once you enter the microsite, you can choose which product you would like to buy and add
                                it to your basket.
                                Finally, add your discount code at checkout to reveal the discounted price.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        How do I redeem my Topgolf discount code?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                The gameplay discount code can be redeemed in person at the venue on the game panel or
                                with a bay host.
                            </AccordionPanel>
                        </AccordionItem>

                        {/*<AccordionItem>*/}
                        {/*    <h2>*/}
                        {/*        <AccordionButton py={8}>*/}
                        {/*            <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'*/}
                        {/*                 fontWeight={950} fontStyle='italic' textTransform='uppercase'>*/}
                        {/*                How do I receive my Topgolf and Bang & Olufsen discount codes?*/}
                        {/*            </Box>*/}
                        {/*            <AccordionIcon/>*/}
                        {/*        </AccordionButton>*/}
                        {/*    </h2>*/}
                        {/*    <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>*/}
                        {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                        {/*        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim*/}
                        {/*        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea*/}
                        {/*        commodo consequat.*/}
                        {/*    </AccordionPanel>*/}
                        {/*</AccordionItem>*/}

                        <AccordionItem>
                            <h2>
                                <AccordionButton py={8}>
                                    <Box as="span" flex='1' textAlign='left' fontSize='28px' fontFamily='FuturaNowAcura'
                                         fontWeight={950} fontStyle='italic' textTransform='uppercase'>
                                        Can I receive more than one Bang & Olufsen and Topgolf discount code?
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize='18px' fontFamily='acuraBespoke' fontWeight='normal'>
                                No, each reservation holder is eligible for one discount code for each Acura partner.
                            </AccordionPanel>
                        </AccordionItem>

                    </Accordion>

                    {/*<Text w={{base: '95%', md: '75%'}} px={{base: '5%', md: '15%'}} color='white' fontSize='14px'*/}
                    {/*      fontFamily='acuraBespoke' fontWeight='normal'>*/}
                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut*/}
                    {/*    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra*/}
                    {/*    maecenas accumsan lacus vel facilisis.*/}
                    {/*</Text>*/}
                    {/*<Box w={{base: '95%', md: '75%'}} px={{base: '5%', md: '15%'}}>*/}
                    {/*    <GeneralTerms  align='left' mt='30px'/>*/}
                    {/*</Box>*/}
                </VStack>
            </Box>
        </AspectRatio>
    )
}