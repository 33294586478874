import {Box, AspectRatio} from "@chakra-ui/react";

export const PerksSection4 = () => {
    return (
        <AspectRatio ratio={{base: 0.536, md: 1.8}} id='section2'>
            <Box
                bgImage='images/bg_perks-section_4_desktop.jpg'
                backgroundSize='cover'
                bgPos={{base: '80% 50%', md: '50% 50%'}}
            />
        </AspectRatio>
    )
}