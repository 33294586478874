import {
    Box, AspectRatio, Text, Heading, VStack, Button, Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from "@chakra-ui/react";

export const TopgolfTerms = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()

    return (
        <>
            <VStack align='left' mt='30px'>
                <Text fontFamily='acuraBespoke' color='white'
                      fontWeight={{base: 'bold', md: 'bold'}}
                      fontSize={{base: '16px', md: '18px'}}
                      cursor='pointer' textDecor='underline' onClick={onOpen}
                >
                    Topgolf Terms & Conditions
                </Text>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose} size='xl' scrollBehavior='inside'>
                <ModalOverlay/>
                <ModalContent >
                    <ModalHeader bg='black' textColor='white' fontFamily='acuraBespoke' fontWeight='bold'>Topgolf Terms & Conditions</ModalHeader>
                    {/*<ModalCloseButton color='white'/>*/}
                    <ModalBody bg='black'>
                        <VStack>
                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                Coupon code is good for $15 off Topgolf game play. Expires 9/30/24. Not valid on
                                Tuesdays. Cannot be used in conjunction with any other coupon, offer or promotion,
                                including Unlimited Play, and is not valid for use on parties or events. May be used
                                only once and applied to only one (1) membership. Only one (1) coupon may be used
                                per group/bay. Any unused value will be forfeited. Cannot be substituted and has no
                                cash value. Not valid for online purchases, including reservations, and must be
                                redeemed at U.S. Topgolf locations. Excludes Lounge, Swing Suite and Toptracer Range
                                locations.
                            </Text>
                        </VStack>
                    </ModalBody>

                    <ModalFooter bg='black'>
                        <Button colorScheme='white' mr={3} onClick={onClose} fontFamily='acuraBespoke' fontWeight='bold'>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}