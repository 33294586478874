import {
    Button,
    Text,
    VStack,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Link,
    useDisclosure, Image,
} from '@chakra-ui/react'
// import {Link} from "react-router-dom";

export const BurgerMenu = ({isOpen, onMenuClose, isPerks}) => {

    const {onOpen, onClose} = useDisclosure()

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onMenuClose}
            size='md'
            returnFocusOnClose={false}
        >
            <DrawerOverlay/>
            <DrawerContent bg='black'>
                <DrawerHeader>
                    <DrawerCloseButton mr='25px' mt='30px'>
                        <Image w='50px' src='images/icon_close_x.png'/>
                    </DrawerCloseButton>
                </DrawerHeader>

                <DrawerBody mt='140px'>
                    <VStack w='100%' alignItems='left'>
                        {isPerks ? null :
                            <Button mb='25px' variant='ghost' _hover={{}} _active={{}} justifyContent='start'
                                    onClick={onMenuClose}
                            >
                                <Link href='#section2'>
                                    <Text
                                        color='white'
                                        fontSize='32px'
                                        fontWeight='950'
                                        fontFamily='FuturaNowAcura'
                                        fontStyle='italic'
                                    >
                                        UNLOCK THE ENERGY
                                    </Text>
                                </Link>
                            </Button>}

                        <Button mb='25px' variant='ghost' _hover={{}} _active={{}} justifyContent='start'
                                onClick={onMenuClose}
                        >
                            <Link href='#perks'>
                                <Text color='white'
                                      fontSize='32px'
                                      fontWeight='950'
                                      fontFamily='FuturaNowAcura'
                                      fontStyle='italic'
                                      textTransform='uppercase'>
                                    perks
                                </Text>
                            </Link>
                        </Button>

                        <Button mb='25px' variant='ghost' _hover={{}} _active={{}} justifyContent='start'
                                onClick={onMenuClose}
                        >
                            <Link href='#section8'>
                                <Text color='white'
                                      fontSize='32px'
                                      fontWeight='950'
                                      fontFamily='FuturaNowAcura'
                                      fontStyle='italic' textTransform='uppercase'>
                                    FAQS
                                </Text>
                            </Link>
                        </Button>

                        {/*<Link*/}
                        {/*    href='https://www.acura.com/tools/reserve-online?modelYear=2024&vehiclemodelSeries=zdx-type-s'*/}
                        {/*    target="_blank"*/}
                        {/*>*/}
                        {/*    <Button mb='25px' variant='ghost' _hover={{}} _active={{}} justifyContent='start'>*/}
                        {/*        <Text color='white'*/}
                        {/*              fontSize='32px'*/}
                        {/*              fontWeight='950'*/}
                        {/*              fontFamily='FuturaNowAcura'*/}
                        {/*              fontStyle='italic' textTransform='uppercase'>*/}
                        {/*            reserve now*/}
                        {/*        </Text>*/}
                        {/*    </Button>*/}
                        {/*</Link>*/}

                        {/*<Link*/}
                        {/*    href='https://acuraverse.com/'*/}
                        {/*    target="_blank"*/}
                        {/*>*/}
                        {/*    <Button mb='25px' variant='ghost' _hover={{}} _active={{}} justifyContent='start'>*/}
                        {/*        <Text color='white'*/}
                        {/*              fontSize='32px'*/}
                        {/*              fontWeight='950'*/}
                        {/*              fontFamily='FuturaNowAcura'*/}
                        {/*              fontStyle='italic' textTransform='uppercase'>*/}
                        {/*            next-gen integra nft*/}
                        {/*        </Text>*/}
                        {/*    </Button>*/}
                        {/*</Link>*/}
                    </VStack>
                </DrawerBody>

                <DrawerFooter justifyContent='flex-start' ml='20px' mb='30px'>
                    <Link
                        href='https://www.acura.com'
                        target="_blank"
                    >
                        <Image src='images/acura_logo.png' w='132px'/>
                    </Link>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}