import {Box, AspectRatio, Center, Image, Text, VStack, Button, Link, HStack, useTimeout} from "@chakra-ui/react";
import {Header} from "../../ui/Header/Header";
import useCodeStore from "../../../state/codeStore";
import {useEffect, useRef, useState} from "react";
import { getDatabase, ref, set } from "firebase/database";
import date from 'date-and-time';

export const PerksSection1 = () => {

    const codes = useCodeStore((state) => state.codeResponse)

    const containerRef = useRef()
    const walletRef = useRef()
    const [arrowPos, setArrowPos] = useState()

    const [codeTracked, setCodeTracked] = useState(false)

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
    const handleResize = () => {
        if (!containerRef.current || !walletRef.current) return

        // const walletRect = walletRef.current.getBoundingClientRect()
        // const walletTop = walletRect.top
        // const walletHeight = walletRect.height

        if (window.innerHeight < containerRef.current.clientHeight) {
            const pos = clamp(window.innerHeight - 125, 600, containerRef.current.clientHeight)
            setArrowPos(pos)
        } else {
            const pos = clamp(containerRef.current.clientHeight - 125, 600, containerRef.current.clientHeight)
            setArrowPos(pos)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
    }, []);

    useEffect(() => {
        handleResize()
    }, [walletRef.current]);

    useEffect(() => {
        handleResize()
    }, [containerRef.current]);

    // Save code to tracker db
    useEffect(() => {
        if (codes && !codeTracked) {
            const zdxCode = codes.zdxCode

            if (!zdxCode) return

            const now = new Date();
            const dateData = date.format(now, 'YYYY/MM/DD')

            // do code save
            const db = getDatabase()
            const codesRef = ref(db, zdxCode)
            set(codesRef, {
                date: dateData
            });

            setCodeTracked(true)
        }
    }, [codes]);

    return (
        <AspectRatio ratio={{base: 0.536, md: 1.40625}} id='section1' ref={containerRef}>
            <Box
                bgImage='images/bg_perks-section_1_desktop.jpg'
                backgroundSize='cover'
                bgPos={{base: '30% 50%', md: '50% 50%'}}
            >
                <Header position='absolute' top={0} perks/>
                <Box h='80%' w='100%' mt='15vh'>
                    <Center>
                        <VStack spacing={{base: 10, md: 14}}>

                            <VStack spacing={{base: 8, md: 8}}>
                                <Image h={{base: '26px', md: '48px'}} src='images/headline.png' display={
                                    {
                                        base: 'inline',
                                        md: 'none'
                                    }
                                }/>

                                <Text color='white' fontSize={{base: '38px', md: '48px'}} fontFamily='FuturaNowAcura'
                                      fontWeight={950}
                                      fontStyle='italic'
                                      textTransform='uppercase' letterSpacing={{base: 7, md: 14}} align='center'
                                      lineHeight={{base: '42px', md: '80px'}}
                                >
                                    Welcome to <br/> the House of Energy
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white' px={{base: '20px', md: '10vw'}}
                                      textAlign='center'
                                      fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '18px'}}>
                                    {/*With the key now in your possession, you can access<br/>exclusive perks and*/}
                                    {/*Acura-sponsored*/}
                                    {/*events.*/}
                                    With the key now in your possession, you can access your exclusive perks.
                                </Text>
                            </VStack>

                            <VStack spacing={8}>
                                <Text fontFamily='acuraBespoke' color='white' px={{base: '20px', md: '10vw'}}
                                      textAlign='center'
                                      fontWeight={{base: 'normal', md: 'normal'}}
                                      fontSize={{base: '16px', md: '16px'}}>
                                    Click below to add your Energy Key<br/>
                                    to your digital wallet.
                                </Text>
                                <HStack spacing={{base: 4, md: 8}}>
                                    <Link href={codes.appleWalletPassLink}>
                                        <Image ref={walletRef} src='images/US-UK_Add_to_Apple_Wallet_RGB_101421 1.png'
                                               w={{base: '146px', md: '158px'}}/>
                                    </Link>
                                    <Link href={codes.googleWalletPassLink}>
                                        <Image src='images/enAU_add_to_google_wallet_add-wallet-badge 1.png'
                                               w={{base: '166px', md: '180px'}}/>
                                    </Link>
                                </HStack>
                            </VStack>

                            {/*<VStack spacing={4} mt='-50px'>*/}
                            {/*    <Text color='white' fontSize='14px' fontFamily='FuturaNowAcura' fontWeight={950}*/}
                            {/*          fontStyle='italic'>*/}
                            {/*        SCROLL BELOW TO CLAIM YOUR PERKS*/}
                            {/*    </Text>*/}
                            {/*    <Button variant='ghost' _hover={{}} _active={{}}>*/}
                            {/*        <Link href='#perks'>*/}
                            {/*            <Image src='images/down_arrow.png' w={{base: '71px', md: '137px'}}/>*/}
                            {/*        </Link>*/}
                            {/*    </Button>*/}
                            {/*</VStack>*/}

                        </VStack>

                        {/*<VStack spacing={4} position='absolute' bottom={{base: '20px', md: '50px'}}>*/}
                        <VStack spacing={4} position='absolute' top={{base: null, md: arrowPos}} bottom={{base: '20px', md: null}}>
                            <Text color='white' fontSize='14px' fontFamily='FuturaNowAcura' fontWeight={950}
                                  fontStyle='italic'>
                                SCROLL BELOW TO CLAIM YOUR PERKS
                            </Text>
                            <Button variant='ghost' _hover={{}} _active={{}}>
                                <Link href='#perks'>
                                    <Image src='images/down_arrow.png' w={{base: '71px', md: '137px'}}/>
                                </Link>
                            </Button>
                        </VStack>
                    </Center>
                </Box>

            </Box>
        </AspectRatio>
    )
}