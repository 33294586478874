import {KeyIdOverlayDesktop} from "./KeyIdOverlayDesktop";
import {KeyIdOverlayMobile} from "./KeyIdOverlayMobile";

export const KeyIdOverlay = ({isOpen, onClose}) => {

    return (
        <>
            <KeyIdOverlayDesktop display={{base: 'none', md: 'inline'}} isOpen={isOpen} onClose={onClose}/>
            <KeyIdOverlayMobile display={{base: 'inline', md: 'none'}} isOpen={isOpen} onClose={onClose}/>
        </>
    )
}