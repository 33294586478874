import {
    Box,
    AspectRatio,
    Image,
    Text,
    VStack,
    Button,
    HStack,
    Flex,
    Link,
    Stack,
    Spacer,
    Center,
    chakra,
} from "@chakra-ui/react";
import {GeneralTerms} from "../../ui/GeneralTerms/GeneralTerms";

export const Footer = () => {
    return (
        <AspectRatio ratio={{base: 0.50, md: 3.2}}>
            <Box
                bgImage='images/bg_section_FOOTER_desktop.jpg'
                backgroundSize='cover'
                bgPos={{base: '49% 50%', md: '50% 50%'}}
            >

                <Flex
                    position='absolute'
                    top='60px'
                    left='30px'
                    align="center"
                    justify="space-between"
                    wrap="wrap"
                    w="100%"
                >
                    <Link
                        href='https://www.acura.com'
                        target="_blank"
                    >
                        <Image src='images/acura_logo.png' w='200px' display={{base: 'none', md: 'inline'}}/>
                    </Link>
                    <HStack mr='20px'>
                        {/*<Link*/}
                        {/*    href='https://www.acura.com/tools/reserve-online?modelYear=2024&vehiclemodelSeries=zdx-type-s'*/}
                        {/*    target="_blank"*/}
                        {/*>*/}
                        {/*    <Button mr='30px' variant='ghost' _hover={{}} _active={{}}*/}
                        {/*            display={{base: 'none', md: 'inline'}}>*/}
                        {/*        <Text color='white' fontSize='20px' fontFamily='FuturaNowAcura' fontWeight={950}*/}
                        {/*              fontStyle='italic'>*/}
                        {/*            RESERVE YOURS*/}
                        {/*        </Text>*/}
                        {/*    </Button>*/}
                        {/*</Link>*/}

                        <Link
                            href='https://acuraverse.com/'
                            target="_blank"
                        >
                            <Button mr='30px' variant='ghost' _hover={{}} _active={{}}
                                    display={{base: 'none', md: 'inline'}}>
                                <Text color='white' fontSize='20px' fontFamily='FuturaNowAcura' fontWeight={950}
                                      fontStyle='italic'>NEXT-GEN INTEGRA NFT</Text>
                            </Button>
                        </Link>
                    </HStack>
                </Flex>

                <Stack
                    direction={{base: 'column', md: 'row'}}
                    position='absolute'
                    px='30px'
                    bottom={{base:'200px', md:'120px'}}
                    w='100%'
                    spacing={8}
                >
                    <VStack align='left'>
                        <HStack spacing={{base: 2, md: 10}}>
                            <Image src='images/logo_zdx.png' w={{base: '87px', md: '110px'}}/>
                            <Box w='1px' h={{base: '32px', md: '60px'}} bg='white'/>

                            <Text color='white' fontSize='18px'
                                  fontFamily='FuturaNowAcura' fontWeight={950}
                                  fontStyle='italic' letterSpacing={12}
                                  display={{base: 'none', md: 'inline'}}
                            >
                                UNLOCK THE ENERGY
                            </Text>

                            <Text color='white' fontSize='14px'
                                  fontFamily='FuturaNowAcura' fontWeight={950}
                                  fontStyle='italic' letterSpacing={12}
                                  display={{base: 'inline', md: 'none'}}
                            >
                                ALL-ELECTRIC
                            </Text>
                        </HStack>
                        <GeneralTerms align='left' mt='15px'/>
                    </VStack>

                    <Spacer/>

                    <Center>
                        <VStack spacing={4}>
                            <HStack spacing={8} justifyContent={{base: 'center', md: 'right'}} w='100%'>
                                <Link href='https://www.facebook.com/Acura' target='_blank'>
                                    <Image
                                        src='images/logo-facebook.svg'
                                        filter='saturate(0%) brightness(600%)'
                                    />
                                </Link>

                                <Link href='https://twitter.com/Acura' target='_blank'>
                                    <Image
                                        src='images/logo-twitter.svg'
                                        filter='saturate(0%) brightness(600%)'
                                    />
                                </Link>

                                <Link href='https://www.instagram.com/acura' target='_blank'>
                                    <Image
                                        src='images/logo-instagram.svg'
                                        filter='saturate(0%) brightness(600%)'
                                    />
                                </Link>

                                <Link href='https://www.youtube.com/user/Acura' target='_blank'>
                                    <Image
                                        src='images/logo-youtube.svg'
                                        filter='saturate(0%) brightness(600%)'
                                    />
                                </Link>
                            </HStack>
                            <HStack spacing={2} align='left'>
                                <Link href='https://www.honda.com/privacy/privacy-notice' target='_blank'>
                                    <Text
                                        fontFamily='acuraBespoke' color='white'
                                        fontWeight={{base: 'bold', md: 'normal'}}
                                        fontSize={{base: '12px', md: '12px'}}
                                    >
                                        Privacy Notice
                                    </Text>
                                </Link>

                                <Text
                                    fontFamily='acuraBespoke' color='white'
                                    fontWeight={{base: 'bold', md: 'normal'}}
                                    fontSize={{base: '12px', md: '12px'}}
                                >
                                    |
                                </Text>

                                {/*<Text*/}
                                {/*    as='a'*/}
                                {/*    className="ot-sdk-show-settings"*/}
                                {/*    fontFamily='acuraBespoke'*/}
                                {/*    fontSize={{base: '12px', md: '12px'}}*/}
                                {/*    fontWeight={{base: 'bold', md: 'normal'}}*/}
                                {/*    color='white'*/}
                                {/*    cursor='pointer'*/}
                                {/*    _before={{content: "'Cookie settings: '"}}*/}
                                {/*>*/}
                                {/*    click here.*/}
                                {/*</Text>*/}

                                {/*<Text*/}
                                {/*    fontFamily='acuraBespoke' color='white'*/}
                                {/*    fontWeight={{base: 'bold', md: 'normal'}}*/}
                                {/*    fontSize={{base: '12px', md: '12px'}}*/}
                                {/*>*/}
                                {/*    |*/}
                                {/*</Text>*/}

                                <Link
                                    href='https://www.honda.com/privacy/your-privacy-choices'
                                    target='_blank'>
                                    <HStack>
                                        <Text
                                            fontFamily='acuraBespoke' color='white'
                                            fontWeight={{base: 'bold', md: 'normal'}}
                                            fontSize={{base: '12px', md: '12px'}}
                                        >
                                            Your Privacy Choices
                                        </Text>
                                        <Image src='/images/privacyoptions123x59.png' h='14px'/>
                                    </HStack>
                                </Link>
                            </HStack>
                            <Box w='100%' textAlign={{base: 'center', md: 'right'}}>
                                <Text
                                    fontFamily='acuraBespoke' color='white'
                                    fontWeight={{base: 'normal', md: 'normal'}}
                                    fontSize={{base: '12px', md: '12px'}}
                                >
                                    Questions? – <chakra.a
                                    href='mailto:UnlockAcuraZDX@mullenlowe.com'
                                ><chakra.span fontWeight='black'>Contact us here</chakra.span></chakra.a>
                                </Text>
                            </Box>
                        </VStack>
                    </Center>
                </Stack>

                <Center
                    position='absolute'
                    px='30px'
                    bottom='40px'
                    w={{base:'90%', md:'80%'}}
                    spacing={8}
                >
                    <Text
                        fontFamily='acuraBespoke' color='white'
                        fontWeight={{base: 'bold', md: 'normal'}}
                        fontSize={{base: '12px', md: '12px'}}
                        textAlign='center'
                    >
                        We and third parties use cookies and similar tools to enhance and track your experience on our
                        Sites, conduct analytics, and personalize marketing to you. By using the Sites, you agree to our
                        use of these technologies. For more information, visit the
                        <chakra.span cursor="pointer" fontWeight='bold'>
                            &nbsp;
                            <Link
                                href='https://www.honda.com/privacy/privacy-notice#cookies'
                                target='_blank'
                                _hover={{}}
                            >
                            Cookies section
                            </Link>
                            &nbsp;
                        </chakra.span>
                            within our Privacy
                            Notice. To manage your Cookie Preferences, <chakra.span cursor="pointer" fontWeight='bold'>
                            <a className="ot-sdk-show-settings">click
                                here.</a></chakra.span>
                    </Text>
                </Center>

            </Box>
        </AspectRatio>
    )
}

