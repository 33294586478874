import {useEffect, useRef, useState} from "react";
import {
    Box,
    AspectRatio,
    Center,
    Image,
    Text,
    Heading,
    VStack,
    Button,
    Input,
    Link,
    FormControl,
    FormErrorMessage
} from "@chakra-ui/react";
import {KeyIdOverlay} from "../../ui/KeyIdOverlay/KeyIdOverlay";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import useCodeStore from "../../../state/codeStore";
import {GeneralTerms} from "../../ui/GeneralTerms/GeneralTerms";

const URL_BASE = 'https://myexpressapp-c4tmomcvta-uc.a.run.app/redeem?key='

export const Section2 = () => {
    const [showOverlay, setShowOverlay] = useState(false)
    const [formError, setFormError] = useState(false)
    const [isFetchingCodes, setIsFetchingCodes] = useState(false)
    const {register, handleSubmit, watch, formState: {errors}} = useForm();

    const setCodeResponse = useCodeStore((state) => state.setCodeResponse)
    const navigate = useNavigate();

    const ref = useRef()
    const [arrowPos, setArrowPos] = useState()

    const [ratio, setRatio] = useState(1.8)
    useEffect(() => {
        const handleResize = () => {
            if (!ref.current) return

            // if (window.innerHeight < ref.current.clientHeight) {
            //     setArrowPos(window.innerHeight - 125)
            // } else {
            //     setArrowPos(ref.current.clientHeight - 125)
            // }
            setRatio(ref.current.clientWidth / ref.current.clientHeight)
        }
        window.addEventListener('resize', handleResize)
        handleResize()
    }, []);

    const onSubmit = async (data) => {
        setIsFetchingCodes(true)
        setFormError(false)
        const zdxCode = data.code
        // console.log(zdxCode)

        const response = await fetch(URL_BASE + zdxCode)
        const codes = await response.json();
        codes.zdxCode = zdxCode
        // console.log(codes)

        if (codes.exists) {
            setCodeResponse(codes)
            navigate('/perks')
        } else {
            setFormError(true)
        }

        setIsFetchingCodes(false)
    }

    return (
        <>
            <AspectRatio ratio={{base: 0.536, md: 1.8}} id='section2' ref={ref}>
                <Box
                    bgImage='images/bg_section_2_desktop.jpg'
                    backgroundSize='cover'
                    bgPos={{base: '30% 80%', md: '50% 50%'}}
                >
                    <Center>
                        <VStack>
                            <Heading as='h1' fontSize={{base: '32px', md: '48px'}} color='white'
                                     fontFamily='FuturaNowAcura' fontWeight={950} fontStyle='italic'
                                     letterSpacing={{base: 16, md: 20}} align='center'
                            >
                                UNLOCK THE ENERGY
                            </Heading>

                            <VStack mt='30px'>
                                <FormControl isInvalid={formError}>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <VStack>
                                            <Input
                                                textAlign='center'
                                                color='white'
                                                w='324px'
                                                placeholder='ENTER ENERGY KEY ID HERE'
                                                fontSize='20px'
                                                fontFamily='FuturaNowAcura'
                                                fontWeight={950}
                                                fontStyle='italic'
                                                _placeholder={
                                                    {
                                                        opacity: 1,
                                                        color: 'white',
                                                        textAlign: 'center',
                                                        fontSize: '20px',
                                                        fontFamily: 'FuturaNowAcura',
                                                        fontWeight: '950',
                                                        fontStyle: 'italic',
                                                    }
                                                }
                                                size='lg'
                                                borderRadius={0}
                                                {...register("code", {required: true})}
                                            />
                                            <FormErrorMessage fontFamily='acuraBespoke' color='white'>The code seems to be wrong</FormErrorMessage>

                                            <Button
                                                as="button"
                                                type='submit'
                                                variant='ghost'
                                                size='sm'
                                                rounded={0}
                                                isLoading={isFetchingCodes}
                                                _hover={{}}
                                                _active={{}}
                                            >
                                                <Text fontFamily='FuturaNowAcura' color='white' fontWeight='bold'>
                                                    SUBMIT
                                                </Text>
                                            </Button>
                                        </VStack>
                                    </form>
                                </FormControl>
                                <Button mt={4} variant='ghost' _hover={{}} _active={{}}
                                        onClick={() => {
                                            setShowOverlay(true)
                                        }}
                                >
                                    <Text fontFamily='acuraBespoke' color='white' textAlign='center' fontWeight='normal'
                                          fontSize='16px'
                                          textDecor='underline'
                                    >
                                        Where can I find my Energy Key ID?
                                    </Text>
                                </Button>
                            </VStack>

                            <Text fontFamily='acuraBespoke' color='white' px={{base: '20px', md: '14vw'}}
                                  textAlign='center' fontWeight={{base: 'normal', md: 'bold'}}
                                  fontSize={{base: '16px', md: '18px'}} mt='30px'>
                                Reservation of an Acura ZDX grants you membership to the House of Energy. Upon entry,
                                you'll receive your Acura Energy Key Card which unlocks exclusive perks.
                            </Text>

                            <GeneralTerms align='center' mt='15px'/>

                            {/*<VStack spacing={4} position='absolute' bottom='50px'>*/}
                            <VStack spacing={4} mt='30px'>
                                <Text color='white' fontSize='14px' fontFamily='FuturaNowAcura' fontWeight={950}
                                      fontStyle='italic'>LEARN
                                    MORE</Text>
                                <Button variant='ghost' _hover={{}} _active={{}}>
                                    <Link href='#perks'>
                                        <Image src='images/down_arrow.png' w={{base: '71px', md: '137px'}}/>
                                    </Link>
                                </Button>
                            </VStack>

                        </VStack>
                    </Center>
                </Box>
            </AspectRatio>

            <KeyIdOverlay isOpen={showOverlay} onClose={() => setShowOverlay(false)}/>
        </>
    )
}