import {Box, AspectRatio} from "@chakra-ui/react";

export const Section3 = () => {
    return (
        <AspectRatio ratio={{base: 0.536, md: 1.8}} id='section3'>
            <Box
                bgImage='images/bg_section_3_desktop.jpg'
                backgroundSize='cover'
                bgPos={{base: '50% 50%', md: '50% 50%'}}
            />
        </AspectRatio>
    )
}