import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts.css'
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
} from "react-router-dom"
import {Providers} from "./providers/providers";
import App from './App';
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
    apiKey: "AIzaSyAWB_Kn4yJvqEd2oCej4fLvLJGWOWKChF0",
    authDomain: "acura-zdx.firebaseapp.com",
    databaseURL: "https://acura-zdx-redeem-tracker.firebaseio.com",
    projectId: "acura-zdx",
    storageBucket: "acura-zdx.appspot.com",
    messagingSenderId: "114408918197",
    appId: "1:114408918197:web:c95f1db54736dec5a742fc",
    measurementId: "G-JDJWG39EK7"
};

const fbApp = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(fbApp, {
    provider: new ReCaptchaEnterpriseProvider("6LdWIlwpAAAAAP0Y1NfvaX1EVovDQ98LGhZEofFz"),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

const router = createBrowserRouter(
    createRoutesFromElements(<Route path="*" element={<App/>}/>)
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        {/*<App />*/}
        <Providers>
            <RouterProvider router={router}/>
        </Providers>
    </React.StrictMode>
);


// ZDX9FH9VCET