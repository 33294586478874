import {useRef, useState, useEffect} from "react";
import {Box, AspectRatio, Center, Image, Text, VStack, Button, Link} from "@chakra-ui/react";
import {Header} from "../../ui/Header/Header";

export const Section1 = () => {
    const ref = useRef()
    const headlineRef = useRef()
    const [arrowPos, setArrowPos] = useState()

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
    const handleResize = () => {
        // if (!ref.current || !headlineRef.current) return

        const headlineRect = headlineRef.current.getBoundingClientRect()
        const headlineTop = headlineRect.top
        const headlineHeight = headlineRect.height
        const headlinePos = headlineTop + headlineHeight

        if (window.innerHeight < ref.current.clientHeight) {
            const pos = clamp(window.innerHeight - 175, headlinePos, ref.current.clientHeight)
            setArrowPos(pos)
        } else {
            const pos = clamp(ref.current.clientHeight - 175, headlinePos, ref.current.clientHeight)
            // setArrowPos(ref.current.clientHeight - 175)
            setArrowPos(pos)
        }

    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        setTimeout(()=>{
            handleResize()
        }, 500)
    }, []);

    useEffect(() => {
        handleResize()
    }, [headlineRef.current]);

    useEffect(() => {
        handleResize()
    }, [ref.current]);

    useEffect(() => {
        if (!ref.current || !headlineRef.current) return
        handleResize()
    });

    return (
        <AspectRatio ratio={{base: 0.536, md: 1.40625}} id='section1' ref={ref}>
            <Box
                bgImage='images/bg_section_1_desktop.jpg'
                backgroundSize='cover'
                bgPos={{base: '30% 50%', md: '50% 50%'}}
            >
                <Header position='absolute' top={0}/>
                <Center>
                    <Image ref={headlineRef} w={{base: '302px', md: '658px'}} src='images/headline.png' mt={{base: '-400px', md: 0}}/>

                    {/*<VStack spacing={4} position='absolute' bottom='125px'>*/}
                    {/*    <Text fontFamily='FuturaNowAcura' color='white' fontSize={{base: '20px', md: '24px'}} fontWeight={800}>INTRODUCING THE 2024 ZDX</Text>*/}
                    {/*    <Text color='white' fontSize='14px' fontFamily='FuturaNowAcura' fontWeight={950} fontStyle='italic'>UNLOCK THE ENERGY*/}
                    {/*        BELOW</Text>*/}
                    {/*    <Button variant='ghost' _hover={{}} _active={{}}>*/}
                    {/*        <Link href='#section2'>*/}
                    {/*            <Image src='images/down_arrow.png' w={{base: '71px', md: '137px'}}/>*/}
                    {/*        </Link>*/}
                    {/*    </Button>*/}
                    {/*</VStack>*/}

                    <VStack spacing={4} position='absolute' top={arrowPos}>
                        <Text fontFamily='FuturaNowAcura' color='white' fontSize={{base: '20px', md: '24px'}} fontWeight={800}>INTRODUCING THE 2024 ZDX</Text>
                        <Text color='white' fontSize='14px' fontFamily='FuturaNowAcura' fontWeight={950} fontStyle='italic'>UNLOCK THE ENERGY
                            BELOW</Text>
                        <Button variant='ghost' _hover={{}} _active={{}}>
                            <Link href='#section2'>
                                <Image src='images/down_arrow.png' w={{base: '71px', md: '137px'}}/>
                            </Link>
                        </Button>
                    </VStack>

                </Center>
            </Box>
        </AspectRatio>
    )
}