import {
    Box, AspectRatio, Text, Heading, VStack, Button, Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from "@chakra-ui/react";

export const BOTerms = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()

    return (
        <>
            <VStack align='left' mt='30px'>
                <Text fontFamily='acuraBespoke' color='white'
                      fontWeight={{base: 'bold', md: 'bold'}}
                      fontSize={{base: '16px', md: '18px'}}
                      cursor='pointer' textDecor='underline' onClick={onOpen}
                >
                    Bang & Olufsen Terms & Conditions
                </Text>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose} size='xl' scrollBehavior='inside'>
                <ModalOverlay/>
                <ModalContent >
                    <ModalHeader bg='black' textColor='white' fontFamily='acuraBespoke' fontWeight='bold'>Bang & Olufsen Terms & Contditions</ModalHeader>
                    <ModalBody bg='black'>
                        <VStack>
                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                This 20% off discount code offered by Bang & Olufsen (“B&O”) may be applied to your
                                order of
                                one
                                (1) product only on https://www.bang-olufsen.com/en/us/story/zdx by applying your unique
                                promotional code at checkout. Code may only be applied to one (1) item. Offer only
                                applies
                                to
                                select products only and order must shipped to a valid address in the U.S. Open to U.S.
                                residents only with a valid billing address in the U.S. Valid from November 20, 2023 at
                                12:00 AM
                                EST to November 19, 2024 at 11:59 PM EST and cannot be combined with any other offer,
                                discount,
                                or promotional code. Cannot be applied to past transactions and is subject to change or
                                cancellation without notice.
                            </Text>
                            <Text fontFamily='acuraBespoke' color='white' fontSize='14px' fontWeight='normal'>
                                Codes have no monetary value, and cannot be converted into currency. Redeemable online
                                only.
                                Code cannot be applied to an order if code is inapplicable, invalid, previously used, or
                                expired. No replacement codes will be issued. Only one code can be applied per order,
                                for
                                one-time use within the promotion period. B&O reserves the right to terminate the use of
                                the
                                code at any time. B&O reserves the right, in its sole and absolute discretion, to refuse
                                or
                                cancel any order for reasons related to fraud, abuse or for any other reason.
                            </Text>
                        </VStack>
                    </ModalBody>

                    <ModalFooter bg='black'>
                        <Button colorScheme='white' mr={3} onClick={onClose} fontFamily='acuraBespoke' fontWeight='bold'>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}