import {Box, AspectRatio, Flex, Center, Image, Text, Heading, VStack, Stack, Button, Input} from "@chakra-ui/react";

export const Section4 = () => {
    return (
        <AspectRatio ratio={{base: 0.325, md: 1.8}} id='perks'>
            <Flex flexDirection={{base: 'column', md: 'row'}}>
                <Box
                    w='100%'
                    h='100%'
                    bgImage='images/bg_section_4L_desktop.jpg'
                    backgroundSize='cover'
                    bgPos={{base: '30% 50%', md: '50% 50%'}}
                >
                    <Center h='100%' p={{base: '8%', md: '25%'}} mt={{base: '-60px', md: '0'}}>
                        <VStack align='left' spacing={8}>
                            <Heading as='h1' fontSize='32px' color='white' fontFamily='FuturaNowAcura' fontWeight={950}
                                     fontStyle='italic'>
                                PARTNER PERKS
                            </Heading>
                            <Text fontFamily='acuraBespoke' color='white' fontSize='18px' fontWeight='normal'
                                  lineHeight={{base: '6', md: '8'}}>
                                Upon reservation of your ZDX, you will receive one discount code for Topgolf and
                                select products from Bang & Olufsen, our Acura partners.
                            </Text>

                            {/*<Text fontFamily='acuraBespoke' color='white' fontSize='18px' fontWeight='normal'*/}
                            {/*      lineHeight={{base: '6', md: '8'}}>*/}
                            {/*    Upon reservation of your all-electric ZDX, you’ll receive exclusive perks for Acura-sponsored events.*/}
                            {/*    <br/><br/>*/}
                            {/*    Stay tuned for details surrounding the Acura Grand Prix of Long Beach.*/}
                            {/*</Text>*/}
                        </VStack>
                    </Center>
                </Box>
                <Box
                    w='100%'
                    h='100%'
                    bgImage='images/bg_section_4R_desktop.jpg'
                    backgroundSize='cover'
                    bgPos={{base: '40% 50%', md: '50% 50%'}}
                />
            </Flex>
        </AspectRatio>
    )
}