import {
    Box, AspectRatio, Text, Heading, VStack, Button, Flex, chakra,
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import useCodeStore from "../../../state/codeStore";
import {BOTerms} from "./BOTerms";
import {TopgolfTerms} from "./TopgolfTerms";

export const PerksSection3 = () => {
    const codes = useCodeStore((state) => state.codeResponse)

    return (
        // <AspectRatio ratio={{base: 0.128, md: 0.687}} id='perks'>
        <AspectRatio ratio={{base: 0.135, md: 0.99}} id='perks'>
            <Box
                bgImage='images/bg_section_8_desktop.jpg'
                backgroundSize='cover'
                bgPos={{base: '0% 50%', md: '50% 50%'}}
            >
                <VStack spacing={{base: 24, md: 48}} w='100%'>

                    <VStack w='100%' spacing={{base: 8, md: 8}} align={{base: 'left', md: 'center'}}>
                        <Heading as='h1' fontSize={{base: '32px', md: '48px'}} color='white' fontFamily='FuturaNowAcura'
                                 fontWeight={950} fontStyle='italic' align={{base: 'left', md: 'center'}}
                                 px={{base: '5%', md: '15%'}}>
                            PARTNER PERKS
                        </Heading>

                        <Text fontFamily='acuraBespoke' color='white' px={{base: '5%', md: '28vw'}}
                              textAlign={{base: 'left', md: 'center'}}
                              fontWeight={{base: 'normal', md: 'bold'}}
                              fontSize={{base: '18px', md: '18px'}}>
                            Upon reservation of your ZDX, you will receive one discount code for Topgolf and for select
                            products from Bang & Olufsen, our Acura partners.
                        </Text>
                    </VStack>

                    <Flex w={{base: '100%', md: '80%'}} flexDirection={{base: 'column', md: 'row'}}>
                        <VStack align='left' spacing={8} flexBasis='100%' px={{base: 4, md: 8}}>
                            <Heading as='h2' fontSize={{base: '32px', md: '32px'}} color='white'
                                     fontFamily='FuturaNowAcura'
                                     fontWeight={950} fontStyle='italic' textTransform='uppercase'
                                     align={{base: 'left', md: 'center'}}>
                                Bang & olufsen
                            </Heading>

                            <VStack align='left' spacing={0.5}>
                                <Text fontFamily='acuraBespoke' color='white' fontWeight={{base: 'normal', md: 'bold'}}
                                      fontSize={{base: '16px', md: '22px'}} textTransform='uppercase'>
                                    20% off 1x b&o product
                                </Text>
                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'normal', md: 'normal'}}
                                      fontSize={{base: '16px', md: '18px'}} textTransform='uppercase'>
                                    Valid: 11/20/23-11/19/24
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'normal', md: 'normal'}}
                                      fontSize={{base: '16px', md: '18px'}} textTransform='uppercase'>
                                    Select products only
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'normal', md: 'normal'}}
                                      fontSize={{base: '16px', md: '18px'}} textTransform='uppercase'>
                                    Your code: <chakra.span fontWeight='bold'>{codes.BO}</chakra.span>
                                </Text>
                            </VStack>

                            <Link
                                to="https://urldefense.com/v3/__https://www.bang-olufsen.com/da/dk/story/zdx__;!!N96JrnIq8IfO5w!niSN5qlFHRdx2lmDNNKj-K19ye4Ev8JQX53VgNfCmBYoOCF-eU49yxaNJO-rUCxaeaYuYBqjR1LU2YVjs8dKvJBE$"
                                target="_blank">
                                <Button
                                    variant='outline'
                                    rounded='0px'
                                    p={4}
                                    w={{base: '345px', md: '446px'}}
                                    whiteSpace="normal"
                                    height="auto"
                                    blockSize="auto"
                                    mt='30px'
                                    _hover={{}}
                                    _active={{}}
                                >
                                    <Text
                                        fontFamily='acuraBespoke'
                                        color='white'
                                        textTransform='uppercase'
                                        fontWeight={{base: 'bold', md: 'bold'}}
                                        fontSize={{base: '18px', md: '22px'}}
                                        w='100%'
                                    >
                                        Click Here to Redeem Your B&O Discount Code.
                                    </Text>
                                </Button>
                            </Link>

                            <VStack align='left' mt='30px' spacing={6}>
                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '22px'}} textTransform='uppercase'>
                                    INSTRUCTIONS TO REDEEM:
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '18px'}}>
                                    1. Copy your unique discount code
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '18px'}}>
                                    2. Click the link above to enter the B&O microsite
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '18px'}}>
                                    3. Select the product you’d like to purchase and add it to your cart
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '18px'}}>
                                    4. Enter the discount code you received to reveal the discounted price
                                </Text>
                            </VStack>

                            {/*<VStack align='left' mt='30px'>*/}
                            {/*    <Text fontFamily='acuraBespoke' color='white'*/}
                            {/*          fontWeight={{base: 'bold', md: 'bold'}}*/}
                            {/*          fontSize={{base: '16px', md: '18px'}}*/}
                            {/*          cursor='pointer' textDecor='underline'*/}
                            {/*    >*/}
                            {/*        Bang & Olufsen Terms & Contditions*/}
                            {/*    </Text>*/}

                            {/*    <Text fontFamily='acuraBespoke' color='white'*/}
                            {/*          fontWeight={{base: 'normal', md: 'normal'}}*/}
                            {/*          fontSize={{base: '16px', md: '18px'}}>*/}
                            {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                            {/*        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices*/}
                            {/*        gravida.*/}
                            {/*        Risus commodo viverra maecenas accumsan lacus vel facilisis.*/}
                            {/*        Valid Through 11/20/23 — 11/19/24*/}
                            {/*    </Text>*/}
                            {/*</VStack>*/}
                            <BOTerms/>
                        </VStack>


                        <VStack align='left' spacing={8} flexBasis='100%' px={{base: 4, md: 8}}
                                mt={{base: '100px', md: '0px'}}
                        >
                            <Heading as='h2' fontSize={{base: '32px', md: '32px'}} color='white'
                                     fontFamily='FuturaNowAcura'
                                     fontWeight={950} fontStyle='italic' textTransform='uppercase'
                                     align={{base: 'left', md: 'center'}}>
                                topgolf
                            </Heading>

                            <VStack align='left' spacing={0.5}>
                                <Text fontFamily='acuraBespoke' color='white' fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '22px'}} textTransform='uppercase'>
                                    $15 off one round at topgolf
                                </Text>
                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'normal', md: 'normal'}}
                                      fontSize={{base: '16px', md: '18px'}} textTransform='uppercase'>
                                    Valid: 11/20/23-9/30/2024
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'normal', md: 'normal'}}
                                      fontSize={{base: '16px', md: '18px'}} textTransform='uppercase'>
                                    Your code: <chakra.span fontWeight='bold'>{codes.TG}</chakra.span>
                                </Text>
                            </VStack>

                            {/*<Button*/}
                            {/*    variant='outline'*/}
                            {/*    rounded='0px'*/}
                            {/*    p={4}*/}
                            {/*    w='196px'*/}
                            {/*    whiteSpace="normal"*/}
                            {/*    height="auto"*/}
                            {/*    blockSize="auto"*/}
                            {/*    mt='30px'*/}
                            {/*    _hover={{}}*/}
                            {/*    _active={{}}*/}
                            {/*>*/}
                            {/*    <Text*/}
                            {/*        fontFamily='acuraBespoke'*/}
                            {/*        color='white'*/}
                            {/*        textTransform='uppercase'*/}
                            {/*        fontWeight={{base: 'bold', md: 'bold'}}*/}
                            {/*        fontSize={{base: '16px', md: '22px'}}*/}
                            {/*        w='100%'*/}
                            {/*    >*/}
                            {/*        claim perk*/}
                            {/*    </Text>*/}
                            {/*</Button>*/}

                            <VStack align='left' mt='30px' spacing={6}>
                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '22px'}} textTransform='uppercase'>
                                    INSTRUCTIONS TO REDEEM:
                                </Text>

                                <Text fontFamily='acuraBespoke' color='white'
                                      fontWeight={{base: 'bold', md: 'bold'}}
                                      fontSize={{base: '16px', md: '18px'}}>
                                    The gameplay discount code can be redeemed in person, at the venue on the game panel
                                    or with a bay host.
                                </Text>
                            </VStack>

                            {/*<VStack align='left' mt='30px'>*/}
                            {/*    <Text fontFamily='acuraBespoke' color='white'*/}
                            {/*          fontWeight={{base: 'bold', md: 'bold'}}*/}
                            {/*          fontSize={{base: '16px', md: '18px'}}>*/}
                            {/*        Topgolf Terms & Conditions*/}
                            {/*    </Text>*/}

                            {/*    <Text fontFamily='acuraBespoke' color='white'*/}
                            {/*          fontWeight={{base: 'normal', md: 'normal'}}*/}
                            {/*          fontSize={{base: '16px', md: '18px'}}>*/}
                            {/*        Coupon code is good for $15 off Topgolf game play. Expires 3/31/24. Not valid on*/}
                            {/*        Tuesdays. Cannot be used in conjunction with any other coupon, offer or promotion,*/}
                            {/*        including Unlimited Play, and is not valid for use on parties or events. May be used*/}
                            {/*        only once and applied to only one (1) membership. Only one (1) coupon may be used*/}
                            {/*        per group/bay. Any unused value will be forfeited. Cannot be substituted and has no*/}
                            {/*        cash value. Not valid for online purchases, including reservations, and must be*/}
                            {/*        redeemed at U.S. Topgolf locations. Excludes Lounge, Swing Suite and Toptracer Range*/}
                            {/*        locations.*/}
                            {/*    </Text>*/}
                            {/*</VStack>*/}
                            <TopgolfTerms/>
                        </VStack>

                    </Flex>

                    {/*<VStack spacing={8} align={{base: 'left', md: 'center'}} px={{base: 4, md: 0}}>*/}
                    {/*    <Heading as='h1' fontSize={{base: '32px', md: '48px'}} color='white' fontFamily='FuturaNowAcura'*/}
                    {/*             fontWeight={950} fontStyle='italic' align={{base: 'left', md: 'center'}}*/}
                    {/*    >*/}
                    {/*        EVENT PERKS*/}
                    {/*    </Heading>*/}
                    {/*    <Text fontFamily='acuraBespoke' color='white'*/}
                    {/*          fontWeight={{base: 'bold', md: 'bold'}}*/}
                    {/*          fontSize={{base: '16px', md: '18px'}}*/}
                    {/*          w={{base: '100%', md: '60%'}} align={{base: 'left', md: 'center'}}*/}
                    {/*    >*/}
                    {/*        Upon reservation of your all-electric ZDX, you’ll receive exclusive perks for*/}
                    {/*        Acura-sponsored events.*/}
                    {/*    </Text>*/}

                    {/*    <Heading as='h2' fontSize={{base: '32px', md: '32px'}} color='white' fontFamily='FuturaNowAcura'*/}
                    {/*             fontWeight={950} fontStyle='italic'*/}
                    {/*             textTransform='uppercase' mt='40px' w={{base: '100%', md: '60%'}}*/}
                    {/*             textAlign={{base: 'left', md: 'center'}}*/}
                    {/*    >*/}
                    {/*        Stay tuned for details surrounding the Acura Grand Prix of Long Beach.*/}
                    {/*        /!*Stay tuned for more exclusive perks once you take possession of your vehicle.*!/*/}
                    {/*    </Heading>*/}
                    {/*</VStack>*/}

                </VStack>
            </Box>
        </AspectRatio>
    )
}