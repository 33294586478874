import {PerksSection1} from "./sections/PerksSection1/PerksSection1";
import {PerksSection2} from "./sections/PerksSection2/PerksSection2";
import {PerksSection3} from "./sections/PerksSection3/PerksSection3";
import {PerksSection4} from "./sections/PerksSection4/PerksSection4";
import {Section8} from "./sections/Section8/Section8";
import {PerksSection5} from "./sections/PerksSection5/PerksSection5";
import {Footer} from "./sections/Footer/Footer";
import {useEffect} from "react";

export const Perks = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <PerksSection1/>
            <PerksSection2/>
            <PerksSection3/>
            <PerksSection4/>
            <Section8/>
            <PerksSection5/>
            <Footer/>
        </>
    )
}