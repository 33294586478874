import {Box, AspectRatio, Center, Image, Text, Heading, VStack, Button, Input} from "@chakra-ui/react";

export const Section7 = () => {
    return (
        <AspectRatio ratio={{base: 0.536, md: 1.8}} id='section7'>
            <Box
                bgImage='images/bg_section_7_desktop.jpg'
                backgroundSize='cover'
                bgPos={{base: '75% 50%', md: '50% 50%'}}
            />
        </AspectRatio>
    )
}