import {useState} from "react";
import {Flex, Image, Button, Center, Link} from "@chakra-ui/react";
import {BurgerMenu} from "../BurgerMenu/BurgerMenu";

export const Header = (props) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const {perks} = props

    return (
        <>
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                wrap="wrap"
                w="100%"
                pt={{base: '30px', md: '60px'}}
                {...props}
            >
                <Link
                    href='https://www.acura.com'
                    target="_blank"
                >
                    <Image w={{base: '117px', md: '200px'}} ml={{base: '15px', md: '30px'}}
                           src='images/acura_logo.png'/>
                </Link>
                <Image w={{base: '48px', md: '491px'}} src='images/headline.png' display={
                    {
                        base: 'none',
                        md: perks ? 'inline' : 'none'
                    }
                }
                       ml={{base: '0px', md: '-85px'}}
                       mt={{base: '0px', md: '3px'}}
                />


                <Button p={0} mr={{base: '15px', md: '50px'}} variant='ghost' _hover={{}} _active={{}} onClick={() => {
                    setMenuOpen(true)
                }}>
                    <Image src='images/btn_burger_menu.png' w={{base: '27px', md: '50px'}}/>
                </Button>
            </Flex>

            <BurgerMenu isOpen={menuOpen} isPerks={perks} onMenuClose={() => {
                setMenuOpen(false)
            }}/>
        </>
    )
}