import {Box, AspectRatio, Flex, Center, Image, Text, Heading, VStack, Button, Input} from "@chakra-ui/react";

export const Section6 = () => {
    return (
        <AspectRatio ratio={{base: 0.268, md: 1.8}} id='section6'>
            <Flex flexDirection={{base: 'column', md: 'row'}}>
                <Box
                    w='100%'
                    h='100%'
                    bgImage='images/bg_section_6L_desktop.jpg'
                    backgroundSize='cover'
                    bgPos={{base: '50% 50%', md: '50% 50%'}}
                >
                    <Center h='100%' p={{base: '35px', md: '92px'}} mt={{base: '-80px', md: '0'}}>
                        <Heading as='h1' fontSize='32px' color='white' fontFamily='FuturaNowAcura' fontWeight={950} fontStyle='italic' lineHeight='52px'>
                            {/*STAY TUNED FOR MORE EXCLUSIVE PERKS ONCE YOU TAKE POSSESSION OF YOUR VEHICLE.*/}
                        </Heading>
                    </Center>
                </Box>
                <Box
                    w='100%'
                    h='100%'
                    bgImage='images/bg_section_6R_desktop.jpg'
                    backgroundSize='cover'
                    bgPos={{base: '80% 50%', md: '50% 50%'}}
                />
            </Flex>
        </AspectRatio>
    )
}